import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Meta from '../components/Meta'
import Layout from '../components/Layout'
import Features from '../components/Features'

function Page({ location }) {
  const { shareImage } = useStaticQuery(graphql`
    query {
      shareImage: file(
        relativePath: { eq: "services/commercial-banner-1.webp" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <>
      <Meta
        title="Commercial Landscaping"
        description="We believe the measure of a good build is impacted by the developer's choice of contractor. Hire us as a landscape contractor for your next development."
        location={location}
        image={shareImage}
      />
      <Layout>
        <section className="lg:flex justify-between bg-gray-lightest border-b-8 border-green">
          <div className="grid justify-center content-center mx-auto py-12 px-8 lg:py-16 lg:px-12">
            <h2 className="uppercase text-4xl font-extrabold sm:text-5xl">
              Commercial landscaping
            </h2>
            <p className="max-w-3xl mt-3 text-xl sm:mt-4 lg:text-2xl">
              Call in ProGro Landscapes as your new contractor
            </p>
          </div>
          <div className="flex-1 max-w-7xl">
            <StaticImage
              src="../images/services/commercial-banner-1.webp"
              alt="Featured commercial work"
              className="aspect-w-16 aspect-h-7"
              aspectRatio={16 / 7}
              layout="fullWidth"
              placeholder="blurred"
              transformOptions={{ fit: 'inside' }}
            />
          </div>
        </section>
        <section className="bg-white">
          <div className="max-w-2xl mx-auto py-8 px-4 sm:py-24 sm:px-6 lg:px-8">
            <h3 className="max-w-xl mt-1 text-4xl font-extrabold text-gray sm:tracking-tight">
              Why should <span className="text-green">ProGro Landscapes</span>{' '}
              be your landscape contractor?
            </h3>
            <p className="max-w-xl mt-5 text-xl text-gray-light">
              We believe the measure of a good build is largely impacted by the
              developer&apos;s choice of each contractor. We would like to
              introduce ourselves as a potential landscape contractor for your
              next development.
            </p>
            <p className="max-w-xl mt-3 text-xl text-gray-light">
              All directors have many years experience working in the commercial
              industry and aim to build lasting relationships. We understand the
              time pressure and can provide results to the deadline. When
              working with very large areas, we have the experience and
              knowledge of working with large machinery to turnover results
              quickly and cost efficiently.
            </p>
          </div>
        </section>
        <section className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray">
                Health and Safety Conscientious
              </h3>
            </div>
            <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative lg:row-start-1 lg:col-start-2">
                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <StaticImage
                      src="../images/services/commercial-banner-2.jpg"
                      alt="Featured work"
                      className="aspect-w-12 aspect-h-7"
                      aspectRatio={12 / 7}
                      placeholder="blurred"
                      layout="fullWidth"
                      transformOptions={{ cropFocus: 'entropy' }}
                    />
                    <figcaption className="mt-3 flex text-sm text-gray-light">
                      Pedestrian project completed in Reading
                    </figcaption>
                  </figure>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-lg text-gray-light">
                    All members of staff are trained in health and safety and
                    provided with the appropriate training on all equipment and
                    machinery used.
                  </p>
                </div>
                <div className="mt-5 prose prose-indigo text-gray-light mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                  <ul>
                    <li>Staff are CSCS Qualified</li>
                    <li>First Aid Qualified</li>
                    <li>Method Statements & Risk Assessments provided</li>
                    <li>Upper Tier Waste Carriers License</li>
                    <li>NPORS Exvacation license</li>
                  </ul>
                  <h4>Fully Insured up to &pound;5,000,000</h4>
                  <p>
                    We are fully insured with public and employee liability and
                    able to work on commercial sites. Documents are provided on
                    request and ProGro will adhere to the client specific
                    requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray text-center">
              Our Clients
            </h3>
            <p className="mt-5 max-w-prose mx-auto text-center font-semibold text-gray-light tracking-wider">
              We understand our clients&apos; needs for professionalism and
              reliability which is why we are successful in rapidly expanding
              our commercial division, and list of satisfied commercial clients.
            </p>
            <div className="mt-6 grid gap-1 lg:grid-cols-5 lg:mt-8">
              <div className="col-span-1 text-center">
                <StaticImage
                  src="../images/clients/carrs-lettings.webp"
                  alt="Carr's Lettings logo"
                />
              </div>
              <div className="col-span-1 text-center">
                <StaticImage
                  src="../images/clients/chancellors.webp"
                  alt="Chancellors logo"
                />
              </div>
              <div className="col-span-1 text-center">
                <StaticImage
                  src="../images/clients/nurture.webp"
                  alt="Nurture logo"
                />
              </div>
              <div className="col-span-1 text-center">
                <StaticImage
                  src="../images/clients/peach-pubs.webp"
                  alt="Peach Pubs logo"
                />
              </div>
              <div className="col-span-1 text-center">
                <StaticImage
                  src="../images/clients/crm-students.webp"
                  alt="CRM Students logo"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray text-center">
            What we provide our clients with
          </h3>
          <p className="mt-5 max-w-prose mx-auto text-center font-semibold text-gray-light tracking-wider">
            Landscaping traverses a number of different trades, and we provide a
            myriad of unique services to achieve the results needed. ProGro
            Landscapes will get it done.
          </p>
          <Features
            list={[
              {
                name: 'Soft Landscaping',
                description: 'Lawn installation and shrub and tree planting.',
              },
              {
                name: 'Hard Landscapes',
                description:
                  'Patio, decking and installation of bespoke planters.',
              },
              {
                name: 'Fencing',
                description:
                  'All types of fencing undertaken with competitive rates.',
              },
              {
                name: 'Site Clearances',
                description:
                  'Cutting out mature hedges/shrubs and excavating roots.',
              },
              {
                name: 'Tree Surgery',
                description: 'Reduction and removal of trees.',
              },
              {
                name: 'Grounds Maintenance',
                description:
                  'Maintaining beautiful landscapes under our thorough maintenance regime.',
              },
            ]}
          />
        </section>
      </Layout>
    </>
  )
}

Page.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Page
